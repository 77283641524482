// @ts-check

// import Search from './search'
// import ActionManager from './action-manager'

/**
 * @typedef {import('./search').DocumentId} DocumentId
 * @typedef {{
 * id: string|number,
 * extra?: Object,
 * linked?: {value: boolean, grantUsersOf: boolean},
 * type: DocumentId["type"]
 * }} RawItem
 * @typedef {import('vue-i18n').TranslateResult} TranslateResult
 * @typedef {import('./action').default} Action
 * @typedef {import('./action-manager').default} ActionManager
 */

export default class ItemList {
  /** @type {Array.<Item>} */
  #items

  /**
   * @param {Array.<RawItem>} items
   * @param {ActionManager} actionManager
   * @param {boolean} logRun
   */
  constructor(items, actionManager, logRun = false) {
    this.#items = items.map(item => new Item(item, actionManager))
  }

  /**
   * @returns {Array.<Item>}
   */
  toArray () {
    return this.#items
  }
}

export class Item {
  /** @type {Map.<string, Action>} */
  #actions = new Map()
  #actionManager
  #key

  /**
   * @param {RawItem} item
   * @param {ActionManager?} actionManager
   */
  constructor(item, actionManager) {
    this.linked = undefined
    this.extra = undefined
    this.id = undefined
    this.type = undefined
    this.#actionManager = actionManager

    // Preserve name and columns data
    Object.assign(this, item)
    // @ts-ignore
    this.#key = Array.isArray(this.id) ? this.id.join(',') : this.id

    this.#actions = actionManager?.getActions(item) ?? new Map()
  }

  /**
   *
   * @returns {Item}
   */
  toCleanedItem () {
    const { extra, id, linked, type } = this
    const i = new Item({ extra, id, linked, type }, this.#actionManager)
    return i
  }

  get actions () {
    return this.#actions
  }

  get quickActions () {
    return [...this.#actions.values()].filter(a => a.canBeQuick)
  }

  get bulkActions () {
    return [...this.#actions.values()].filter(a => !a.canOnlyBeQuick)
  }

  get key () {
    return this.#key
  }

  get hasQuickActions () {
    return Boolean(this.quickActions.filter(a => a.validateRules([this])).length)
  }

  toJSON () {
    return { id: this.id, type: this.type }
  }
}
